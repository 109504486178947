import Repository from './Repository';
import {AxiosResponse} from "axios";

const resource = '/auth';

export default new class{
    getListLoi130(st: string): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/check130/${st}`);
    }
    getListBA(t1: number, t2: number, khoaPhong_Id: number): Promise<AxiosResponse<any>>{
        return Repository.get<any>(`${resource}/listBA/${t1}/${t2}/${khoaPhong_Id}`);
    }
}
