

import { computed, ref } from 'vue'
import moment from 'moment';
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
import check130Repository from "@/services/Check130Repository";
import {FilterMatchMode} from "primevue/api"
import {Dulieu130} from "@/models/dulieu130";
import QLTSRepository from "@/services/QLTSRepository";
import {Dulieu130_Khoa} from "@/models/dulieu130_khoa";

export default {

  setup(){
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const listLoi130  = ref([] as Dulieu130[]);
    const listLoi130_BA = ref([] as Dulieu130[]);
    const maBenhAn = ref("");
    const loadingBar = ref(false);
    const date = ref();
    const khoaPhong_id = ref(0);
    const listDuLieu_khoa = ref([] as Dulieu130_Khoa[]);

    const dsKhoaPhong = ref([
      {label: "Khoa Ngoại Phụ", value: 22},
      {label: "Khoa Nội Nhi", value: 23},
      {label: "Khoa Nội Tổng Hợp", value: 28},
      {label: "Khoa Nội Tổng Hợp 2", value: 146},
      {label: "Khoa Ngoại Phụ 2", value: 164},
      {label: "Khoa Châm Cứu Dưỡng Sinh", value: 66},
      {label: "Khoa Châm Cứu Dưỡng Sinh Cơ Sở 2", value: 69},
    ]);

    const loadLoiDuLieu130 = () => {
      loadingBar.value = true;
      listLoi130.value = [];
      check130Repository.getListLoi130(maBenhAn.value)
          .then((response) => {
            listLoi130.value = response.data;
            loadingBar.value = false;
          })
          .catch(err => {
            loadingBar.value = false;
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const loadListBA= () => {
      loadingBar.value = true;
      listLoi130_BA.value = [];
      if(date.value != null)
      check130Repository.getListBA(date.value[0]/1000, date.value[1]/1000, khoaPhong_id.value)
          .then((response) => {
            listDuLieu_khoa.value = response.data;
            loadingBar.value = false;
          })
          .catch(err => {
            loadingBar.value = false;
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 2000
            });
          });
    }

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    const formatDateTime = (date) => {
      if((date == null)||(date == ''))
      {
        return "chưa có thông tin"
      }
      else
        return moment(String(date)).format('HH:mm DD/MM/YYYY');
    };

    const valid = computed(()=> true);

    const expandedRowGroups = ref();

    const expandedRows = ref();

    return {
      error,
      filters,
      clearFilter,
      initFilters,
      formatDateTime,
      loadingBar,
      loadLoiDuLieu130,
      listLoi130,
      maBenhAn,
      expandedRowGroups,
      date,
      loadListBA,
      listLoi130_BA,
      listDuLieu_khoa,
      khoaPhong_id,
      dsKhoaPhong,
      expandedRows
    }
  }
}

